<script setup lang="ts">
import { useDateFormat } from "@vueuse/core";
import type { components } from "@/schemas/contool";

const props = defineProps<{
  event: components["schemas"]["EventDto"];
}>();

const startDateLabel = useDateFormat(
  new Date(props.event.start_date),
  "ddd, D MMM YYYY",
);
</script>

<template>
  <div
    class="p-2 bg-white rounded-lg w-96 h-80 border flex flex-col gap-4 transition-transform duration-300 bg-center bg-cover"
  >
    <nuxt-img
      :src="event.thumbnail_url"
      :alt="event.title"
      height="128"
      class="h-32 object-cover rounded-lg"
      placeholder
    />
    <div class="p-3 rounded flex flex-col space-y-2 w-full">
      <strong class="font-display font-semibold line-clamp-1">
        {{ event.title }}
      </strong>

      <div class="space-x-2 flex items-center opacity-50">
        <u-icon name="i-heroicons-map-pin" />
        <span class="text-sm line-clamp-1">
          {{ event.location_name }}
        </span>
      </div>
      <div class="space-x-2 flex items-center opacity-50">
        <u-icon name="i-heroicons-calendar" />
        <span class="text-sm line-clamp-1">
          {{ startDateLabel }}
        </span>
      </div>
      <span class="text-sm opacity-70 line-clamp-2">
        {{ event.subtitle }}
      </span>
    </div>
  </div>
</template>
