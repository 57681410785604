<script setup lang="ts">
const { data: events, isLoading, suspense } = useContool("/v1/event", "get");

onServerPrefetch(async () => {
  await suspense();
});

const localePath = useLocalePath();

const pageUrl = (id: number, title: string) => {
  const slug = title
    .toLowerCase()
    .replaceAll(/[^\da-z]+/g, "-")
    .replaceAll(/(^-|-$)+/g, "");

  return `${localePath("/events")}/${id}/${slug}`;
};
</script>

<template>
  <div class="flex gap-4 flex-col md:flex-row flex-wrap">
    <u-skeleton v-if="isLoading" class="w-full md:w-96 h-80" />
    <u-link
      v-for="event in events"
      v-else
      :key="event.id"
      class="hover:scale-[1.02] transition-transform duration-300 text-start"
      :to="pageUrl(event.id, event.title)"
    >
      <events-event-card :event="event" class="w-full md:w-96" />
    </u-link>
  </div>
</template>
